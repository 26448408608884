import React from 'react'
import cx from 'classnames';
import SectionIntegration from './SectionIntegrations/SectionIntegration';


const RenderIntegrations = ({integrations}) => {

    //console.log("integrations => ", integrations.everprice)

    const integrationsItems = integrations.everprice?.children ?  integrations.everprice.children : null

    const testIntegrationsItems = integrationsItems ? [...integrationsItems, ...integrationsItems] : [];
    return (
        <>
            {
                integrationsItems.map( (integrationItem, i) => {
                    return (
                    <SectionIntegration
                        key={integrationItem.property + i}
                        integration={integrationItem}
                        />
                    )
                })
            }
        </>
    )
}

export default RenderIntegrations

