import * as c from '../../../constants';
import uniq from 'lodash/uniq';
import { getLocaleFromLanguageCode } from '../../../intl-helpers';
import selectors from '../../../reducers/appReducer'
const locale = getLocaleFromLanguageCode(selectors.getLanguage)

export const getFilterOptions = (parameters) => {
    const { category, details, language , i18n} = parameters

    if (category.type === c.SECTION_TYPE_FILES || category.type === c.SECTION_TYPE_IMAGES) {
        if (details.assets[category.key].length === 0) {
            return []
        }

        const options = details.assets[category.key].reduce((assetClasses, asset) => {
            asset.asset_classes.forEach((klass) => {
                assetClasses[klass.code] = {
                    value: klass.code,
                    label: klass.label[locale],
                }
            })

            return assetClasses;
        }, {})

        return [].concat(
            {
                value: 'all',
                label: i18n._('all'),
            },
            ...Object.values(options)
        )
    }

    if (category.key === c.SECTION_KEY_CORRELATI) {
        const types = details[c.SECTION_KEY_CORRELATI].map((c) => c.type);

        const options = uniq(types)
            .sort()
            .map((t) => {
                return {
                    value: t,
                    label: t,
                }
            })

        return [].concat(
            {
                value: 'all',
                label: i18n._('all'),
            },
            ...options
        )
    }

    return []

}