import format from 'date-fns/format';
import toPairs from 'lodash/toPairs';
import isArray from 'lodash/isArray';
import isString from 'lodash/isString';
import upperFirst from 'lodash/upperFirst';
import * as c from '../../constants';

/**
 * Gets the status label based on the status and detail provided.
 *
 * @param {string} status - The current status of the process.
 * @param {string} detail - Additional details about the status.
 * @returns {string} - The label representing the status.
 */
export function getStatusLabel(status, detail) {
    if (detail === 'ERRORE') {
        return "Errore nell'elaborazione";
    }

    switch (status) {
        case 'ended':
            return 'Completata';
        case 'running':
            return 'In corso';
        case 'starting':
            return 'In attesa';
        default:
            return '-';
    }
}

/**
 * Formats the filters into a human-readable format.
 *
 * @param {Object} filters - The filters to format.
 * @returns {Array} - The formatted filters as an array of JSX elements.
 */
export function formatFilters(filters) {
    return toPairs(filters).map(([key, value]) => {
        if (key === 'pre_classification_roots') {
            return null;
        }

        const textValue = isArray(value)
            ? isString(value[0])
                ? value.join(', ')
                : formatEtimFeatures(value)
            : value;

        // TODO: per valori ETIM usare qualcosa tipo funzione getEtimValue di ItemDataCategory.js o formatAttributeValue di filtersReducer.js

        return (
            <span key={key}>
                <b>{keyToLabel(key)}</b>: {textValue}
            </span>
        );
    });
}

/**
 * Formats ETIM features into a human-readable string.
 *
 * @param {Array} features - The list of ETIM features to format.
 * @returns {string} - The formatted ETIM features.
 */
export function formatEtimFeatures(features) {
    return features
        .map((f) => {
            const label = f.name;

            let value = '';

            switch (f.type) {
                case c.ATTRIBUTE_TYPE_ALPHANUMERIC:
                    value = f.value.join(', ');
                    break;
                case c.ATTRIBUTE_TYPE_LOGIC:
                    value = f.value ? 'Si' : 'No';
                    break;
                case c.ATTRIBUTE_TYPE_NUMERIC:
                    value = f.value;
                    break;
                case c.ATTRIBUTE_TYPE_RANGE:
                    value = `${f.value[0][0]} - ${f.value[0][1]}`;
                    break;
                default:
                    value = f.value;
            }

            return `${label}: ${value}`;
        })
        .join(', ');
}

/**
 * Converts a filter key to a human-readable label.
 *
 * @param {string} key - The filter key to convert.
 * @returns {string} - The human-readable label for the key.
 */
export function keyToLabel(key) {
    switch (key) {
        case 'marche':
            return 'Marche';
        case 'product_tree':
            return 'Tassonomia';
        case 'q':
            return 'Ricerca libera';
        default:
            return upperFirst(key);
    }
}
