import SortableTableHeader from '../../utils/SortableTableHeader';
import { Trans } from '@lingui/react';
import React from 'react';
import { getFilteredArticoliCorrelati } from './ItemDataCategoryTableUtils';
import { CorrelatiRow } from './CorrelatiRow';

const TYPE_ATTR = 'type'
const CODICE_ARTICOLO_ATTR = 'codice_articolo'

const RenderCorrelatiList = ({
     data,
     stateSortBy,
     stateSortDirection,
     toggleSort,
     getSorting}
) => {
    const rows = getFilteredArticoliCorrelati(data, stateSortBy, stateSortDirection)

    return (
        <table className={'table table-striped table-striped-alt table-hover table-dense'}>
            <thead>
            <tr>
                <SortableTableHeader
                    attr={TYPE_ATTR}
                    onClick={toggleSort}
                    sorting={getSorting}
                    headerStyle={{ width: '150px' }}
                >
                    <Trans id="type" />
                </SortableTableHeader>
                <SortableTableHeader
                    attr={CODICE_ARTICOLO_ATTR}
                    onClick={toggleSort}
                    sorting={getSorting}
                    headerStyle={{ width: '150px' }}
                >
                    <Trans id="article:code" />
                </SortableTableHeader>
                <th>
                    <Trans id="article:description" />
                </th>
                <SortableTableHeader
                    attr="qty"
                    onClick={toggleSort}
                    sorting={getSorting}
                    headerStyle={{ width: '100px' }}
                >
                    <Trans id="quantity" />
                </SortableTableHeader>
            </tr>
            </thead>
            <tbody>
            {rows.map((attr, i) => (
                <CorrelatiRow article={attr} language={data.i18n._language} key={i} />
            ))}
            </tbody>
        </table>
    );
}

export default RenderCorrelatiList