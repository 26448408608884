import * as c from '../../../constants';
import get from 'lodash/get';
import { getIntlNumberFormat, getLocaleFromLanguageCode } from '../../../intl-helpers';
const baseIntl = getIntlNumberFormat()

export const getEtimLabel =  (etim_features, id, locale) => {
    return etim_features[id].label[locale] || etim_features[id].label.key
}

export const getEtimValue = (etim_features, feature, locale, i18n) => {
    const currentFeature = etim_features[feature.id];

    switch (currentFeature.type) {
        case c.ATTRIBUTE_TYPE_ALPHANUMERIC:
            return get(feature, `data.label[${locale}]`);
        case c.ATTRIBUTE_TYPE_LOGIC:
            return feature.data === false ? i18n._('no') : i18n._('yes');
        case c.ATTRIBUTE_TYPE_NUMERIC:
            let result = baseIntl.format(feature.data);

            if (currentFeature.unit_of_measure) {
                result = `${result} ${get(
                    currentFeature,
                    `unit_of_measure.description[${locale}]`
                )}`;
            }

            return result;
        case c.ATTRIBUTE_TYPE_RANGE:
            let resultR = `${feature.data[0]} - ${feature.data[1]}`;

            if (currentFeature.unit_of_measure) {
                resultR = `${resultR} ${get(
                    currentFeature,
                    `unit_of_measure.description[${locale}]`
                )}`;
            }

            return resultR;
        default:
            return feature.data;
    }
}