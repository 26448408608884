import SortableTableHeader from '../../utils/SortableTableHeader';
import { Trans } from '@lingui/react';
import LimitText from '../../utils/LimitText';
import { format, parse } from 'date-fns';
import React from 'react';
import { ItemDataCategoryTable } from '../../ItemDataCategoryTable';


const RenderListini = ({attributes, toggleSort, getSorting}) => {
    return (
        <table className="table table-striped table-striped-alt table-hover table-dense">
            <thead>
            <tr>
                <SortableTableHeader
                    attr="codice_listino"
                    onClick={toggleSort}
                    sorting={getSorting}
                    headerStyle={{ width: '30px' }}
                >
                    <Trans id="listino:code" />
                </SortableTableHeader>
                <SortableTableHeader
                    attr="codice_listino_prod"
                    onClick={toggleSort}
                    sorting={getSorting}
                    headerStyle={{ width: '50px' }}
                >
                    <Trans id="listino:code:prod" />
                </SortableTableHeader>
                <SortableTableHeader
                    attr="descrizione_listino"
                    onClick={toggleSort}
                    sorting={getSorting}
                    headerStyle={{ width: '100px' }}
                >
                    <Trans id="listino:descrizione" />
                </SortableTableHeader>
                <SortableTableHeader
                    attr="data_listino"
                    onClick={toggleSort}
                    sorting={getSorting}
                    headerStyle={{ width: '80px' }}
                >
                    <Trans id="listino:date" />
                </SortableTableHeader>
                <SortableTableHeader
                    attr="data_validita"
                    onClick={toggleSort}
                    sorting={getSorting}
                    headerStyle={{ width: '80px' }}
                >
                    <Trans id="data validita" />
                </SortableTableHeader>
                <SortableTableHeader
                    attr="codice_revisione"
                    onClick={toggleSort}
                    sorting={getSorting}
                    headerStyle={{ width: '20px' }}
                >
                    <Trans id="codice revisione" />
                </SortableTableHeader>
                <SortableTableHeader
                    attr="descrizione_revisione"
                    onClick={toggleSort}
                    sorting={getSorting}
                    headerStyle={{ width: '50px' }}
                >
                    <Trans id="descrizione revisione" />
                </SortableTableHeader>
                <SortableTableHeader
                    attr="data_validita_rev"
                    onClick={toggleSort}
                    sorting={getSorting}
                    headerStyle={{ width: '80px' }}
                >
                    <Trans id="data validita revisione" />
                </SortableTableHeader>
            </tr>
            </thead>
            <tbody>
            {attributes.map((listino, i) => (
                <tr key={i}>
                    <td>{listino.codice_listino}</td>
                    <td>{listino.codice_listino_prod}</td>
                    <td>
                        <LimitText limit={40} text={listino.descrizione_listino} />
                    </td>
                    <td>{format(parse(listino.data_listino), 'DD/MM/YYYY')}</td>
                    <td>{format(parse(listino.data_validita), 'DD/MM/YYYY')}</td>
                    <td>
                        {listino.codice_revisione !== '00' ? listino.codice_revisione : ''}
                    </td>
                    <td>
                        {listino.codice_revisione !== '00' ? (
                            <LimitText limit={40} text={listino.descrizione_revisione} />
                        ) : (
                            ''
                        )}
                    </td>
                    <td>
                        {listino.codice_revisione !== '00'
                            ? format(parse(listino.data_validita_rev), 'DD/MM/YYYY')
                            : ''}
                    </td>
                </tr>
            ))}
            </tbody>
        </table>
    );
}

export default RenderListini