import React, { Component } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import InfiniteTree from 'react-infinite-tree';
import { getItemLabel, getLocale, sortTree } from './SectorsTreeUtils';
import { hash } from 'immutable';
import Loader from '../utils/Loader';
import SearchBar from './SectorsTreeSearchBar';
import TreeNode from './SectorsTreeNode'; // Importa il nuovo componente SearchBar
import { localizeLeafsName, searchNodesToOpen } from './SectorsTreeUtils';


class SectorsTree extends Component {
    tree = null;

    constructor(props) {
        super(props);

        this.state = {
            openNodes: 0,
        };

        // Debounced filter function to avoid excessive filtering
        this.debouncedFilterTree = debounce(this.filterTree, 500);
    }

    componentDidMount() {
        this.loadTreeFromProps();
    }

    componentDidUpdate(prevProps) {
        const hasHashChanged = hash(prevProps.data) !== hash(this.props.data);
        const hasFetchFinished = prevProps.isFetching === true && this.props.isFetching === false;
        const hasLanguageChange = prevProps.language !== this.props.language;

        if (hasHashChanged || hasFetchFinished || hasLanguageChange) {
            this.loadTreeFromProps();
            this.mantainSelectedLeaf();
        } else if (prevProps.selectedItem !== this.props.selectedItem) {
            this.props.selectedItem === null && this.loadTreeFromProps();
            this.mantainSelectedLeaf();
        }
    }

    loadTreeFromProps() {
        if (this.tree) {
            const localizedTree = this.props.data.toJS().map((item) =>
                localizeLeafsName(item, getItemLabel)
            );

            const sortedTree = sortTree(localizedTree, getLocale());
            this.tree.loadData(sortedTree);
        }
    }

    mantainSelectedLeaf() {
        if (this.tree) {
            const node = this.tree.getNodeById(this.props.selectedItem);
            const selectedNode = this.tree.getSelectedNode();

            if (node && node !== selectedNode) {
                const parent = node.getParent();
                if (parent.id !== null) {
                    this.tree.openNode(parent);
                    const grandPa = parent.getParent();
                    if (grandPa.id !== null) {
                        this.tree.openNode(grandPa);
                    }
                    this.tree.scrollToNode(selectedNode);
                }
            }
        }
    }

    handleItemClick = (node) => {
        this.tree.selectNode(node);
        this.props.onItemClick(node.id, node.state.depth);
    };

    filterTree = () => {
        const filterOptions = {
            caseSensitive: false,
            exactMatch: false,
            includeAncestors: true,
            includeDescendants: true,
        };

        const { filterValue } = this.state;

        const words = filterValue.match(/\b(\w+)\b/g);

        if (words === null || words.length === 1) {
            this.tree.filter(filterValue, filterOptions);
        } else {
            this.tree.filter((node) => {
                const wordsLength = words.length;
                const res = words.reduce((t, w) => {
                    const i = node.name.toLowerCase().indexOf(w.toLowerCase());
                    if (i >= 0) {
                        t += 1;
                    }
                    return t;
                }, 0);

                return res >= wordsLength;
            }, filterOptions);
        }

        if (this.tree && filterValue !== '') {
            const newTree = searchNodesToOpen(this.tree.state.rootNode.children, filterValue);
            this.tree.loadData(newTree);
        }
    };

    handleFilterChange = (filterValue) => {
        this.setState({ filterValue });
        this.debouncedFilterTree();
    };

    handleFilter = () => {
        this.filterTree();
    };

    render() {
        if (this.props.isFetching) {
            return <Loader />;
        }

        return (
            <>
                <SearchBar
                    onFilterChange={this.handleFilterChange}
                    onFilter={this.handleFilter}
                    disabled={this.props.selectedItem && this.props.classificationType === 'etim'}
                />
                <InfiniteTree
                    ref={(node) => (this.tree = node ? node.tree : null)}
                    autoOpen={false}
                    selectable={true}
                    height={this.state.openNodes * 24 > 120 ? this.state.openNodes * 24 + 64 : 120}
                    width="100%"
                    rowHeight={24}
                    onContentDidUpdate={() => {
                        // to test this refactor
                        // if (this.tree && this.state.openNodes !== this.tree.nodes.length)
                        if (this.tree) {
                            this.setState({ openNodes: this.tree.nodes.length });
                        }
                    }}
                    rowRenderer={(rowProps) => {
                        const { tree, node } = rowProps;

                        return (
                            <TreeNode
                                node={node}
                                tree={tree}
                                selectedItem={this.props.selectedItem}
                                onItemClick={this.handleItemClick}
                            />
                        );
                    }}
                />
            </>
        );
    }
}

SectorsTree.propTypes = {
    data: PropTypes.object,
    selectedItem: PropTypes.string,
    language: PropTypes.string,
    onItemClick: PropTypes.func,
    sidebarHeight: PropTypes.number,
    isFetching: PropTypes.bool,
    classificationType: PropTypes.string,
};

SectorsTree.defaultProps = {
    data: [],
};

export default SectorsTree