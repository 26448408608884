import React from 'react';
import { Trans } from '@lingui/react';
import get from 'lodash/get';
import { getScontoAumentoString } from './ArticleDetailsBodyUtils';

export function ModalScontoAumento({ onClose, details }) {
    const scontoAumentoString = getScontoAumentoString(details);

    return (
        <div className="bg-white drop-shadow-2xl rounded-lg border border-gray-500">
            <div className="modal-header">
                <div className="modal-title flex h6">
                    <div className="grow">
                        <Trans id="brand status" />{' '}
                        {get(details, 'identificazione.descrizione_marca.value')}
                    </div>
                    <span
                        className="btn btn-clear float-right"
                        aria-label="Close"
                        onClick={onClose}
                    />
                </div>
            </div>
            <div className="modal-body border-t">
                <div className="content px-1 pb-2">
                    <Trans id="uncalculated percentage changes" />:{' ' + scontoAumentoString}
                </div>
            </div>
        </div>
    );
}

export default ModalScontoAumento;