import get from 'lodash/get';
import { ARTICLE_DETAIL_SECTIONS } from '../../../constants';
import { getCategoryButtonStatus, getDescrizioneMarcaFromArticolo } from '../../../helpers';
import sortBy from 'lodash/sortBy';
import { getLocaleFromLanguageCode } from '../../../intl-helpers';
import selectors from '../../../reducers/appReducer'

const categories = Object.values(ARTICLE_DETAIL_SECTIONS);
const orderedCategoriesKeys = sortBy(categories, 'order').map((c) => c.key);

export function getValidCategories(details) {
    if (!details) return [];
    const status = getCategoryButtonStatus(details);
    return Object.keys(status).filter((key) => status[key]);
}

export function getScontoAumento(details) {
    let sum = 0;

    for (let i = 1; i < 6; i++) {
        const value = get(details, `varie.sconto_aumento_${i}.value`, 0);

        if (value) {
            sum += value;
        }
    }

    return sum !== 0;
}

export function getScontoAumentoString(details) {
    let entries = [];

    for (let i = 1; i < 6; i++) {
        const value = get(details, `varie.sconto_aumento_${i}.value`, 0);

        if (value && value !== 0) {
            const sign = value > 0 ? '+' : '-';
            entries.push(`${sign}${value}%`);
        }
    }

    return entries.join(', ');
}

export function getImgContainerStyle(articleImgPath) {
    let base = {
        height: '100%',
        width: '100%',
        position: 'relative',
    };

    if (articleImgPath) {
        return {
            ...base,
            background: `url("${articleImgPath}")`,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
        };
    }

    return base;
}
export function getMarcaArticolo(details) {
    return getDescrizioneMarcaFromArticolo(
        details,
        getLocaleFromLanguageCode(selectors.getLanguage)
    );
}

export function getCodiceArticolo(details) {
    return get(details, 'identificazione.codice_articolo.value');
}

export function getDescrizioneArticolo(details) {
    return get(
        details,
        `identificazione.descrizione_articolo.value.${getLocaleFromLanguageCode(
            selectors.getLanguage
        )}`
    );
}
