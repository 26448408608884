import React from 'react'
import PropTypes from 'prop-types'
import debounce from 'lodash/debounce';

class SearchBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            filterValue: '',
        }
    }

    handleInputChange = (e) => {
        const { value } = e.target
        this.setState({ filterValue: value })
        this.props.onFilterChange(value)
    }

    handleFilterClick = () => {
        this.props.onFilter()
    }

    handleClearClick = () => {
        this.setState({ filterValue: '' }, () => {
            this.props.onFilterChange('')
            this.props.onFilter()
        })
    }

    render() {
        const { filterValue } = this.state
        const { disabled } = this.props

        return (
            <div className="input-group" style={{ marginBottom: '10px' }}>
                <input
                    type="text"
                    className="form-input"
                    onChange={this.handleInputChange}
                    onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                            this.handleFilterClick();
                        }
                    }}
                    value={filterValue}
                    disabled={disabled}
                />
                <button
                    type="button"
                    className="btn input-group-btn"
                    onClick={this.handleClearClick}
                    disabled={filterValue === ''}
                >
                    <i className="icon icon-cross"></i>
                </button>
                <button
                    type="button"
                    className="btn btn-secondary input-group-btn"
                    onClick={this.handleFilterClick}
                    disabled={disabled}
                >
                    <i className="icon icon-search" />
                </button>
            </div>
        )
    }
}

SearchBar.propTypes = {
    onFilterChange: PropTypes.func.isRequired,
    onFilter: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
}

SearchBar.defaultProps = {
    disabled: false,
}

export default SearchBar
