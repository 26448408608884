import React, { useMemo } from 'react';
import parse from 'date-fns/parse';
import differenceInDays from 'date-fns/difference_in_days';
import Loader from '../utils/Loader';
import { Trans } from '@lingui/react';
import { DataTable, useDataTableState } from '@ingruz/tabulisk';
import { useQuery } from 'react-query';
import { getFilteredExports } from '../../api';
import format from 'date-fns/format';
import { subDays } from 'date-fns';
import { getColumns } from './DataTableColumns';

const DataTableExportPanel = (props) => {

    const reducer = useDataTableState({
        sortBy: [
            {
                id: 'started_at',
                desc: true,
            },
        ],
    })

    function PaginatorInfo({ page, pageCount, total }) {
        return (
            <>
                Pagina {page} di {pageCount} ({total} risultati totali)
            </>
        );
    }

    const initialSortBy = useMemo(() => {
        return [
            {
                id: 'started_at',
                desc: true,
            },
        ];
    }, [])

    const dateFrom = subDays(new Date(), props.exportsListDaysLimit)

    const queryFilters = {
        started_from: format(dateFrom, 'YYYY-MM-DD'),
        started_to: format(new Date(), 'YYYY-MM-DD'),
    }

    const {
        // isLoading,
        isFetching,
        data: exportsData,
        refetch,
    } = useQuery(['filteredExports', queryFilters], getFilteredExports, {
        refetchOnWindowFocus: false,
    });

    const columns = React.useMemo(() => getColumns(), [])

    return (
        <DataTable
            data={
                isFetching
                    ? []
                    : exportsData.data.results.filter((row) => {
                        if (row.type === null || row.type !== props.exportType) {
                            return false;
                        }

                        // console.log(row);

                        const date = parse(
                            row.started_at,
                            'yyyy-MM-dd HH:mm:ss',
                            new Date()
                        );

                        const diff = differenceInDays(new Date(), date);

                        return diff <= props.exportsListDaysLimit || 30;
                    })
            }
            columns={columns}
            useReducer={reducer}
            config={{
                useFlex: false,
                remote: false,
                paginate: true,
                // debug: true,
                skipAutoReset: true,
                showPageSizeSelector: false,
                PaginatorInfoComponent: PaginatorInfo,
                noRowsMessage: isFetching ? (
                    <Loader />
                ) : (
                    <div className="bg-gray py-2 text-center">
                        <Trans
                            id="no export in the last n days"
                            values={{ count: props.exportsListDaysLimit }}
                        />
                    </div>
                ),
                classNames: {
                    tableClassName: 'table table-striped table-hover table-dense',
                },
                initialSortBy,
            }}
        />
    )
}

export default DataTableExportPanel