import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withI18n } from '@lingui/react';
import { connect } from 'react-redux';
import uniq from 'lodash/uniq'

import * as c from '../constants'
import Modal from './utils/Modal'
import ItemDataCategoryBody from './ItemDataCategoryBody'
import ArticleDataGalleryModal from './ArticleDataGalleryModal'
import { selectors } from '../reducers/appReducer'
import { getFilterOptions } from './ArticleComponents/ArticleDetailsCategoryTab/ArticleDetailsCategoryGetFilterOptions';
import { getAttributes } from './ArticleComponents/ArticleDetailsCategoryTab/ArticleDetailsCategoryGetAttributes';


class ItemDataCategory extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            // activeItems: [],
            showModal: false,
            // currentCategory: null, // Necessario???
            startingIndex: null,
            selectedFilter: {
                value: 'all',
                label: props.i18n._('all'),
            },
            extFilter: {
                value: 'all',
                label: props.i18n._('all'),
            },
        };
    }

    getExtOptions(category, attributes) {
        if (category.type !== c.SECTION_TYPE_IMAGES) {
            return [];
        }

        // console.log(attributes);

        let allExts = [];

        attributes.forEach((attribute) => {
            allExts.push(attribute.ext);
        });

        const options = uniq(allExts).map((ext) => ({
            value: ext,
            label: ext?.toUpperCase(),
        }));

        return [].concat(
            {
                value: 'all',
                label: this.props.i18n._('all'),
            },
            ...options.filter((o) => o.value !== null)
        );
    }

    handleFilterChange = (option) => {
        this.setState({
            selectedFilter: option,
        });
    };

    handleExtFilterChange = (option) => {
        this.setState({
            extFilter: option,
        });
    };

    showModal = (index) => {
        this.setState({
            showModal: true,
            // currentCategory: category,
            startingIndex: index,
        });
    };

    closeModal = () => {
        this.setState({
            showModal: false,
            startingIndex: null,
        });
    };

    render() {
        const { showModal, startingIndex, selectedFilter, extFilter } =
            this.state;

        const { category } = this.props;

        const attributes = getAttributes(this.props);

        return (
            <Fragment>
                <Modal isOpen={showModal} modalRootClass="modal-ultrawide">
                    <ArticleDataGalleryModal
                        items={getAttributes(this.props)}
                        startingIndex={startingIndex}
                        onClose={this.closeModal}
                    />
                </Modal>
                <ItemDataCategoryBody
                    attributes={attributes}
                    filterOptions={getFilterOptions(this.props)}
                    selectedFilter={selectedFilter}
                    extFilter={extFilter}
                    extOptions={this.getExtOptions(category, attributes)}
                    category={category}
                    onFileClick={this.showModal}
                    onFilterChange={this.handleFilterChange}
                    onExtFilterChange={this.handleExtFilterChange}
                    articleDetails={this.props.details}
                />
            </Fragment>
        );
    }
}

ItemDataCategory.propTypes = {
    item: PropTypes.object,
    category: PropTypes.object,
    language: PropTypes.string,
};

function mapStateToProps(state) {
    return {
        priceDecimalPrecision: selectors.getPriceDecimalPrecision(state),
    };
}

const ConnectedItemDataCategory = connect(mapStateToProps)(ItemDataCategory);

export default withI18n()(ConnectedItemDataCategory);
