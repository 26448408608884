import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Downshift from 'downshift';
import AutosizeInput from 'react-input-autosize';
import VirtualList from 'react-tiny-virtual-list';
import { Trans, withI18n } from '@lingui/react';
import cx from 'classnames';
import find from 'lodash/find';
import fuzzaldrin from 'fuzzaldrin-plus';
import sortBy from 'lodash/sortBy';
// import truncate from 'lodash/truncate';

function fuzzyFilter(items, input) {
    return fuzzaldrin.filter(items, input, { key: 'localizedLabel' });
}

// function formatPlaceholder(placeholder) {
//     if (placeholder.length > 33) {
//         return truncate(placeholder);
//     }

//     return placeholder;
// }

class DropdownMultiple extends Component {
    state = {
        inputValue: '',
        isOpen: false,
        scrollTop: 0,
    };

    handleStateChange = (changes, downshiftStateAndHelpers) => {
        const { isOpen, type } = changes;

        let newState = {};
        // console.warn(isOpen);

        // console.warn(type);
        // console.warn(Downshift.stateChangeTypes);

        const shouldUpdateIsOpen =
            type !== Downshift.stateChangeTypes.clickItem &&
            type !== Downshift.stateChangeTypes.clickButton &&
            typeof isOpen !== 'undefined';

        // console.warn('shouldUpdate', shouldUpdateIsOpen);

        if (shouldUpdateIsOpen) {
            newState.isOpen = isOpen;
        }

        const isOpenToCompare = shouldUpdateIsOpen ? isOpen : downshiftStateAndHelpers.isOpen;

        if (isOpenToCompare === false) {
            newState.inputValue = '';
        }

        this.setState(newState);

        if (this.props.onChangedState) {
            this.props.onChangedState(changes, downshiftStateAndHelpers);
        }
    };

    onInputChange = (event) => {
        this.setState({ inputValue: event.target.value });
    };

    onWrapperClick = (e) => {
        if (this._inputWrapper === e.target || this._input === e.target) {
            this.focusOnInput();
            e.stopPropagation();
            e.preventDefault();
        }

        this.props.onLoad();
    };

    focusOnInput() {
        this._input.focus();
        if (typeof this._input.getInput === 'function') {
            this._input.getInput().focus();
        }
    }

    handleToggleMenu = () => {
        this.setState(({ isOpen }) => ({ isOpen: !isOpen }));
    };

    renderIcon(selectedItem, isOpen, clearSelection, getToggleButtonProps) {
        if (this.props.disabled) {
            return null;
        }

        if (this.props.isLoading) {
            return <i className="form-icon loading" />;
        }

        const res = [];

        if (selectedItem && selectedItem.length > 0 && this.props.clearable) {
            res.push(
                <i
                    className="form-icon icon icon-cross c-hand"
                    onClick={clearSelection}
                    style={{ right: '1rem' }}
                    key={'clear'}
                />
            );
        }

        res.push(
            <i
                className={`form-icon icon icon-arrow-${isOpen ? 'up' : 'down'} c-hand`}
                key={'arrow'}
                {...getToggleButtonProps({
                    disabled: this.props.disabled,
                    onClick: this.handleToggleMenu,
                })}
            />
        );

        return res;
    }

    renderNormalList(
        displayItems,
        highlightedIndex,
        selectedItem,
        inputValue,
        itemValueKey,
        itemToString,
        getItemProps
    ) {
        return (
            <div className="menu" style={{ transform: 'none', padding: 0 }}>
                {displayItems.map((item, index) => {
                    const isSelected = find(selectedItem, {
                        value: item.value,
                    });

                    const itemClassName = cx('dropdownList-menu-item', 'c-hand', {
                        isActive: highlightedIndex === index,
                        isSelected,
                    });

                    return (
                        <div
                            key={item[itemValueKey]}
                            className={itemClassName}
                            {...getItemProps({
                                index,
                                item,
                            })}
                        >
                            {inputValue === '' ? (
                                <span>
                                    {isSelected && (
                                        <i
                                            className="icon icon-check"
                                            style={{
                                                marginRight: '2px',
                                            }}
                                        />
                                    )}
                                    {itemToString(item)}
                                </span>
                            ) : (
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: fuzzaldrin.wrap(itemToString(item), inputValue),
                                    }}
                                />
                            )}
                        </div>
                    );
                })}
            </div>
        );
    }

    renderVirtualList(
        displayItems,
        highlightedIndex,
        selectedItem,
        inputValue,
        itemValueKey,
        itemToString,
        getItemProps,
        scrollTop
    ) {
        return (
            <VirtualList
                width="100%"
                scrollOffset={scrollTop}
                scrollToAlignment="auto"
                height={displayItems.length < 10 ? displayItems.length * 34 : 340}
                itemCount={displayItems.length}
                itemSize={34}
                onScroll={(scrollTop) => this.setState({ scrollTop })}
                renderItem={({ index, style }) => {
                    const item = displayItems[index];

                    const isSelected = find(selectedItem, {
                        value: item.value,
                    });

                    const itemClassName = cx('dropdownList-menu-item', 'c-hand', {
                        isActive: highlightedIndex === index,
                        isSelected,
                    });

                    return (
                        <div
                            key={item[itemValueKey]}
                            className={itemClassName}
                            {...getItemProps({
                                index,
                                item,
                                style,
                            })}
                        >
                            {inputValue === '' ? (
                                <span>
                                    {isSelected && (
                                        <i
                                            className="icon icon-check"
                                            style={{
                                                marginRight: '2px',
                                            }}
                                        />
                                    )}
                                    {itemToString(item)}
                                </span>
                            ) : (
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: fuzzaldrin.wrap(itemToString(item), inputValue),
                                    }}
                                />
                            )}
                        </div>
                    );
                }}
            />
        );
    }

    // getNotSelectedItems = () => {
    //     const { items, selectedItem } = this.props;

    //     if (selectedItem.length === 0) {
    //         return items;
    //     }

    //     const selectedIds = selectedItem.map(i => i.value);

    //     return filter(items, i => {
    //         return !includes(selectedIds, i.value);
    //     });
    // };

    getDisplayItems = (items, value) => {
        // TODO: sarebbe il caso di memorizzare il risultato...
        items = items.map((item) => {
            const localizedLabel = this.props.itemToString(item);

            return {
                ...item,
                localizedLabel,
                sortingLabel: this.props.itemToSortString
                    ? this.props.itemToSortString(item)
                    : localizedLabel,
            };
        });

        if (this.props.clearable === false && this.props.selectedItem.length > 0) {
            return sortBy(items, (r) => r.sortingLabel.toLowerCase());;
        }

        if (value === '') {
            return sortBy(items, (r) => r.sortingLabel.toLowerCase());;
        }

        const result = fuzzyFilter(items, value);

        return sortBy(result, (r) => r.sortingLabel.toLowerCase());
    };

    inputRef = (c) => {
        this._input = c;
    };

    render() {
        const {
            clearable,
            disabled,
            items,
            itemToString,
            isLoading,
            placeholder,
            itemLabelKey,
            itemValueKey,
            onChange,
            i18n,
            wrapperStyle,
            onClear,
            onWrapperClick,
            className,
            showPlaceholder,
            useVirtualList,
            showSelectedItemsBadge,
            inputClassName,
            ...rest
        } = this.props;

        const { inputValue, isOpen } = this.state;

        // console.warn(inputClassName);

        return (
            <Downshift
                itemToString={itemToString}
                itemCount={items.length}
                isOpen={isOpen}
                onChange={onChange}
                onStateChange={this.handleStateChange}
                {...rest}
            >
                {({
                    getInputProps,
                    getToggleButtonProps,
                    getItemProps,
                    clearSelection,
                    // isOpen,
                    selectedItem,
                    highlightedIndex,
                }) => {
                    const displayItems = sortBy(this.getDisplayItems(items, inputValue), 'label');

                    // Non mostro niente nell'input se il menu è chiuso e non e stato selezionato nessun oggetto
                    const defaultInputProps = getInputProps({
                        disabled,
                        value: this.state.inputValue,
                        onChange: this.onInputChange,
                        ref: this.inputRef,
                        className: inputClassName,
                        // placeholder: selectedItem.length === 0 && showPlaceholder ? placeholder : ''
                        placeholder: showPlaceholder ? placeholder : '',
                    });

                    if (isOpen === false && selectedItem.length === 0) {
                        defaultInputProps.value = '';
                    }

                    const additionalClass = selectedItem.length === 0 ? '' : 'has-values';

                    const renderList = useVirtualList
                        ? this.renderVirtualList
                        : this.renderNormalList;

                    // console.warn(isOpen);

                    // console.log(defaultInputProps);

                    return (
                        <div
                            className={`dropdownList dropdownMultiple has-icon-right ${className} ${additionalClass}`}
                            style={wrapperStyle}
                            onClick={onWrapperClick}
                        >
                            <div
                                className="inputWrapper"
                                ref={(ref) => (this._inputWrapper = ref)}
                                onClick={this.onWrapperClick}
                                tabIndex="-1"
                                // data-tooltip={placeholder}
                            >
                                {showSelectedItemsBadge && selectedItem.length > 0 && (
                                    <span className="label label-rounded label-primary popover">
                                        <Trans
                                            id="filter:active:count"
                                            values={{
                                                count: selectedItem.length,
                                            }}
                                        />
                                        <div className="popover-container">
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="text-bold text-primary">
                                                        {placeholder}
                                                    </div>
                                                    {selectedItem.map((item, i) => (
                                                        <span
                                                            className="label"
                                                            style={{
                                                                marginRight: '5px',
                                                            }}
                                                            key={i}
                                                        >
                                                            {itemToString(item)}
                                                        </span>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </span>
                                )}
                                <div
                                    style={{
                                        width: '100%',
                                        overflow: 'hidden',
                                        display: showSelectedItemsBadge ? 'inline' : 'auto',
                                    }}
                                >
                                    {showSelectedItemsBadge ? (
                                        <AutosizeInput {...defaultInputProps} />
                                    ) : (
                                        <input {...defaultInputProps} style={{ width: '100%' }} />
                                    )}
                                </div>
                                {/*{

                                    selectedItem.map((item, i) => (
                                    <span
                                        className="label label-rounded label-primary"
                                        style={{ marginRight: '5px' }}
                                        key={i}
                                    >
                                        {item.code}{' '}
                                        <i
                                            className="icon icon-cross c-hand"
                                            style={{
                                                position: 'relative',
                                                top: '-2px'
                                            }}
                                            onClick={() =>
                                                this.props.onRemove(item)
                                            }
                                        />
                                    </span>
                                ))}*/}
                                {this.renderIcon(
                                    selectedItem,
                                    isOpen,
                                    () => {
                                        if (onClear) {
                                            onClear()
                                        } else if (clearSelection) {
                                            clearSelection()
                                        }
                                        if (this.props.onCustomChange) {
                                            //console.log(' on custom change ####')
                                            this.props.onCustomChange([])
                                        }
                                    },
                                    getToggleButtonProps
                                )}
                            </div>
                            {!isOpen ? null : (
                                <div className="dropdownList-menu">
                                    {displayItems.length === 0 ? (
                                        <div className="dropdownList-menu-item">
                                            {isLoading ? (
                                                <Trans id="Please wait" />
                                            ) : (
                                                <Trans id="no:result" />
                                            )}
                                        </div>
                                    ) : (
                                        renderList(
                                            displayItems,
                                            highlightedIndex,
                                            selectedItem,
                                            inputValue,
                                            itemValueKey,
                                            itemToString,
                                            getItemProps,
                                            this.state.scrollTop
                                        )
                                    )}
                                </div>
                            )}
                        </div>
                    );
                }}
            </Downshift>
        );
    }
}

DropdownMultiple.propTypes = {
    autoSortItems: PropTypes.bool,
    className: PropTypes.string,
    clearable: PropTypes.bool,
    disabled: PropTypes.bool,
    inputClassName: PropTypes.string,
    isLoading: PropTypes.bool,
    items: PropTypes.array,
    itemToString: PropTypes.func,
    onChange: PropTypes.func,
    onChangedState: PropTypes.func,
    onRemove: PropTypes.func,
    onWrapperClick: PropTypes.func,
    itemLabelKey: PropTypes.string,
    itemValueKey: PropTypes.string,
    placeholder: PropTypes.string,
    wrapperStyle: PropTypes.object,
    showPlaceholder: PropTypes.bool,
    showSelectedItemsBadge: PropTypes.bool,
};

DropdownMultiple.defaultProps = {
    autoSortItems: true,
    className: '',
    clearable: true,
    disabled: false,
    inputClassName: '',
    isLoading: false,
    items: [],
    itemToString: (i) => (i ? i.label : ''),
    onChange: () => {},
    onChangedState: () => {},
    onWrapperClick: () => {},
    itemLabelKey: 'label',
    itemValueKey: 'value',
    wrapperStyle: {},
    showPlaceholder: false,
    showSelectedItemsBadge: false,
};

export default withI18n()(DropdownMultiple);
