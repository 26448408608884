import React, { useEffect, useState } from 'react';
import { fetchItemByBrandAndCode } from '../../../api';
import { getArticleDescription } from './ItemDataCategoryTableUtils';
import NewWindow from 'react-new-window';
import { ArticleDetailsModal } from '../../ArticleDetailsModal';


export const CorrelatiRow = ({article, language}) => {
    const [description, setDescription] = useState('...');
    const [articleData, setArticleData] = useState(null);
    const [showCorrelato, setShowCorrelato] = useState(false);

    useEffect(() => {
        setDescription('...');
        fetchItemByBrandAndCode(article.sigla_marca, article.codice_articolo)
            .then((res) => {
                setArticleData(res.data);
                setDescription(getArticleDescription(res.data.children, language));
            })
            .catch(() => {
                setDescription('-');
            });
        // eslint-disable-next-line
    }, [article.codice_articolo]);

    return (
        <>
            {showCorrelato && (
                <NewWindow
                    title={description}
                    features={{
                        width: 1024,
                        height: 768,
                    }}
                    onUnload={() => {
                        setTimeout(() => {
                            setShowCorrelato(false);
                        }, 500);
                    }}
                    onOpen={(w) => {
                        w.document.body.style.setProperty(
                            '--color-alternate-row',
                            window.document.body.style.getPropertyValue('--color-alternate-row')
                        );

                        w.document.body.style.setProperty(
                            '--color-primary',
                            window.document.body.style.getPropertyValue('--color-primary')
                        );
                    }}
                >
                    <ArticleDetailsModal
                        article={{ id: articleData ? articleData.id : null }}
                        isModal={false}
                        containerId={`article-detail-modal-${articleData ? articleData.id : ''}`}
                        articleCode={article.codice_articolo}
                        articleBrand={article.sigla_marca}
                        //onClose={() => setShowCorrelato(false)}
                    />
                </NewWindow>
            )}
            <tr>
                <td>{article.type}</td>
                <td>
                    <span
                        style={{
                            cursor: articleData ? 'pointer' : 'default',
                            color: articleData ? 'var(--color-primary)' : 'inherit',
                            fontWeight: articleData ? 'bold' : 'normal',
                        }}
                        onClick={() => articleData && setShowCorrelato(true)}
                    >
                        {article.codice_articolo}
                    </span>
                </td>
                <td>
                    {description}
                    {/* {articleData ? (
                        <a
                            href={`/dp/app?showArticle=${articleData.id}`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {description}
                        </a>
                    ) : (
                        description
                    )} */}
                </td>
                <td>{article.qty}</td>
            </tr>
        </>
    );
}