import React from 'react';
import { Trans } from '@lingui/macro'; // Importa Trans o qualsiasi altro componente necessario
import { formatFilters, getStatusLabel } from './DataTablesUtils'; // Importa le tue funzioni

export const getColumns = () => [
    {
        accessor: 'started_at',
        Header: <Trans id="request time" />,
    },
    {
        accessor: 'info.name_channel',
        Header: <Trans id="exchange format" />,
    },
    {
        accessor: 'filter',
        Header: <Trans id="active filters" />,
        disableSortBy: true,
        Cell: ({ value }) => {
            return <div className="flex space-x-2">{formatFilters(value)}</div>;
        },
    },
    {
        accessor: 'asset',
        Header: <Trans id="asset" />,
    },
    {
        accessor: 'notes',
        Header: <Trans id="note" />,
        disableSortBy: true,
    },
    {
        accessor: 'status',
        Header: <Trans id="status" />,
        Cell: ({ value, row }) => getStatusLabel(value, row.original.info.detail),
    },
    {
        accessor: 'download',
        Header: <Trans id="download" />,
        disableSortBy: true,
        Cell: ({ row }) => {
            if (row.original.output) {
                return (
                    <a className="text-primary" href={row.original.output} target="_parent">
                        <Trans id="download" />
                    </a>
                );
            }

            return null;
        },
    },
];
