import React, { Component, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Trans, withI18n } from '@lingui/react';

import { connect } from 'react-redux';

import * as constants from '../constants';
import { getAssetThumbUrl } from '../api';
import { selectors } from '../reducers/userReducer';

import RenderEmpty from './ArticleComponents/ItemDataCategoryTable/RenderEmpty'
import RenderFileList from './ArticleComponents/ItemDataCategoryTable/RenderFileList'
import RenderListini from './ArticleComponents/ItemDataCategoryTable/RenderListini'
import RenderImageList from './ArticleComponents/ItemDataCategoryTable/RenderImageList'
import RenderTextList from './ArticleComponents/ItemDataCategoryTable/RenderTextList';
import RenderIntegrations from './ArticleComponents/ItemDataCategoryTable/RenderIntegrations';

export class ItemDataCategoryTable extends Component {
    state = {
        sortBy: null,
        sortDirection: 'asc',
    };

    getSorting() {
        return this.state;
    }

    toggleSort = (field) => {
        console.log(field)
        this.setState((prevState) => {
            return {
                sortBy: field,
                sortDirection:
                    prevState.sortBy !== field
                        ? 'asc'
                        : prevState.sortDirection === 'asc'
                        ? 'desc'
                        : 'asc',
            };
        });
    };

    // renderTextList() {
    //     const { category } = this.props;
    //
    //     const isCorrelati = category.key === constants.SECTION_KEY_CORRELATI;
    //
    //     return isCorrelati ?
    //         <RenderCorrelatiList
    //             data={this.props}
    //             sortBy={this.state.sortBy}
    //             sortDirection={this.state.sortDirection}
    //             toggleSort={this.toggleSort}
    //             getSorting={this.getSorting()}
    //         />
    //         :
    //         <RenderSimpleTextList
    //             attributes={this.props.attributes}
    //             type={this.props.type}
    //         />
    // }

    getFileThumbnailPath(fileName) {
        return getAssetThumbUrl(fileName);
    }

    renderList() {
        const { type } = this.props

        switch (type) {
            case constants.SECTION_TYPE_FILES:
                return (
                    <RenderFileList
                        data={this.props}
                        stateSortBy={this.state.sortBy}
                        stateSortDirection={this.state.sortDirection}
                        user={this.props.user.toJS()}
                        toggleSort={this.toggleSort}
                        getSorting={this.getSorting()}
                    />
                )
            case constants.SECTION_TYPE_IMAGES:
                return (
                    <RenderImageList
                    data={this.props}
                    user={this.props.user.toJS()}
                    stateSortBy={this.state.sortBy}
                    stateSortDirection={this.state.sortDirection}
                    toggleSort={this.toggleSort}
                    getSorting={this.getSorting()}
                    />
                )
            case constants.SECTION_TYPE_LISTINI:
                return (
                    <RenderListini
                        attributes={this.props.attributes}
                        toggleSort={this.toggleSort}
                        getSorting={this.getSorting()}
                    />
                )
            case constants.SECTION_TYPE_INTEGRATIONS:
                const everprice = this.props.articleDetails.integrations ? this.props.articleDetails.integrations : null
                return (
                    <RenderIntegrations
                        integrations={everprice}
                    />
                )
            default:
                return (
                    <RenderTextList
                        data={this.props}
                        attributes={this.props.attributes}
                        category={this.props.category}
                        type={this.props.type}
                        sortBy={this.state.sortBy}
                        sortDirection={this.state.sortDirection}
                        toggleSort={this.toggleSort}
                        getSorting={this.getSorting()}
                    />
                )
        }
    }

    render() {
        const { attributes } = this.props;

        // console.warn(attributes, this.props)

        return attributes.length === 0 ?
            <RenderEmpty />
            :
            this.renderList()
    }
}

ItemDataCategoryTable.propTypes = {
    selectedFilter: PropTypes.string,
    attributes: PropTypes.array,
    category: PropTypes.object,
    isRepeatable: PropTypes.bool,
    onClick: PropTypes.func,
    showPreview: PropTypes.bool,
    type: PropTypes.string,
};

ItemDataCategoryTable.defaultProps = {
    attributes: [],
};

function mapStateToProps(state) {
    return {
        user: selectors.getUser(state),
    };
}

const ConnectedItemDataCategoryTable = connect(mapStateToProps)(ItemDataCategoryTable);

export default withI18n()(ConnectedItemDataCategoryTable);
