import React from 'react'
import NewWindow from 'react-new-window'
import { ArticleDetailsModal } from '../../ArticleDetailsModal'
import selectors from '../../../reducers/appReducer'

const ArticlePrintWindow = (props) => {
    return (

        <NewWindow
            title={props.title}
            onUnload={props.onUnload}
            features={{
                menubar: 1,
                width: 1024,
            }}
            copyStyles={true}
            onOpen={(w) => {
                w.document.body.style.setProperty(
                    '--color-alternate-row',
                    window.document.body.style.getPropertyValue(
                        '--color-alternate-row'
                    )
                );

                w.document.body.style.setProperty(
                    '--color-primary',
                    window.document.body.style.getPropertyValue('--color-primary')
                );
            }}
        >
            <ArticleDetailsModal
                article={props.article}
                details={props.details}
                containerId={`article-detail-modal-${props.article.id}`}
                initialCategories={props.activeCategories}
                productProperties={props.productProperties}
                etimClassDetails={props.etimClassDetails}
                language={selectors.getLanguage}
                isPreview={true}
            />
        </NewWindow>
    )
}
export default ArticlePrintWindow;