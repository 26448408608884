import { format } from 'date-fns'

// Date in ISO format to "d/m/Y"
export const dateFromDatetime = (datetime) => {
    if (!datetime) return null
    const date = new Date(datetime)
    if (isNaN(date)) return null
    return format(date, 'DD/MM/YYYY')
}

// Timestamp (millseconds) in "d/m/Y"
export const dateFromTimestamp = (timestamp) => {
    if (!timestamp) return null
    const date = new Date(parseInt(timestamp, 10))
    if (isNaN(date)) return null
    return format(date, 'DD/MM/YYYY')
}

// Integrations main value
export const getIntegrationValue = (item) => {
    const regexDate = /(datainiziovalidita|dataterminevalidita|launched)/i

    if(!regexDate.test(item.property)) return item.value

    switch (item.property) {
        case 'datainiziovalidita':
        case 'dataterminevalidita':
            return dateFromDatetime(item.value)
        case 'launched':
            return dateFromTimestamp(item.value)
        default:
            return item.value
    }
}

// Integrations prices ( prezzi calcolati )
export const getAttributeValue = (item) => {

    const regexPrice = /(listino|prezzo|costo)/i
    const regexDate = /(adata|dadata)/i

    if (regexDate.test(item.property)) {
        const dateValue = item.value

        if (!dateValue || !/^\d{4}-\d{2}-\d{2}$/.test(dateValue)) {
            return item.value
        }

        const [year, month, day] = dateValue.split('-');
        return `${day}/${month}/${year}`
    }

    if (regexPrice.test(item.property)) {
        const numericValue = parseFloat(item.value)
        if (isNaN(numericValue)) return item.value

        return (
            <strong>
                € {numericValue.toFixed(2).replace('.', ',')}
            </strong>
        );
    }

    return item.value
}


export const sortChildrenProperties = (item, orderedProperties) => {
    const desiredOrder = orderedProperties ? orderedProperties : []

    item.children.sort((a, b) => {
        const indexA = desiredOrder.indexOf(a.property);
        const indexB = desiredOrder.indexOf(b.property);

        if (indexA !== -1 && indexB !== -1) {
            return indexA - indexB;
        }

        // Se uno dei due non è nell'ordine desiderato, lo metti alla fine
        if (indexA === -1) return 1;
        if (indexB === -1) return -1;

        return 0;
    })

    return item.children

}

export const desideredOrder = [
    'dadata',
    'adata',
    'pervalore',
    'perquantita',
    'prezzolistinofornitore',
    'costoacquisto',
    'costomaggiorato',
    'listinovendita1',
    'listinovendita2',
    'listinovendita3',
    'listinovendita4',
    'listinovendita5',
    'listinovendita6',
    'listinovendita7',
    'listinovendita8',
    'listinovendita9',
    'listinovendita10',
    'note'
]
