import { Record, List } from 'immutable';

export const SelectFilterRecord = Record({
    enabled: false,
    options: List([]),
    value: null
});

export const OptionRecord = Record({
    value: null,
    label: null,
    code: null,
    extra: null
});

export const BooleanStateRecord = Record({
    value: false,
})
