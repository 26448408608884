import { Trans } from '@lingui/react'
import React from 'react'

const RenderEmpty = () => {
    return (
        <div className="empty">
            <Trans id="no:information:available" />
        </div>
    )
}

export default RenderEmpty