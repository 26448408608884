import React from 'react'
import PrezzoCalcolatoTable from './PrezzoCalcolatoTable';
import { desideredOrder, getIntegrationValue, sortChildrenProperties } from './IntegrationsUtils';
import PrezzoCalcolatoTableBody from './PrezzoCalcolatoTableBody';

const SectionIntegration = ({integration}) => {
    const integrationChildren = integration.children

    const excludedProperties = ['siglafornitore', 'siglamarca', 'prezzi_calcolati', 'datainiziovalidita', 'dataterminevalidita', 'codiceconvenzione', 'descrizioneconvenzione']

    const integrationFiltered = integrationChildren.filter(
        item => !excludedProperties.includes(item.property)
    )

    // Group the date into single array
    const dateProperties = ['datainiziovalidita', 'dataterminevalidita']
    const integrationsDates = integrationChildren.filter(
        item => dateProperties.includes(item.property)
    )
    // Group code and description into single array
    const codeDescriptionProperties = ['codiceconvenzione', 'descrizioneconvenzione']
    const integrationsCodeDescription = integrationChildren.filter(
        item => codeDescriptionProperties.includes(item.property)
    )

    const prezziCalcolatiObject = integrationChildren.find(item => item.property === "prezzi_calcolati")
    const prezziCalcolati = prezziCalcolatiObject?.children ? prezziCalcolatiObject.children : null

    const prezziCalcolatiHeading = prezziCalcolati[0]?.children ? prezziCalcolati[0]?.children : null

    const prezziCalcolatiHeadingSorted = sortChildrenProperties(prezziCalcolati[0], desideredOrder)

    const prezziCalcolatiHeadingSortedFiltered = prezziCalcolatiHeadingSorted.filter(
        item => desideredOrder.includes(item.property)
    )

    return (
        <div className="section-integrations container-full-width overflow-hidden">
            <p><strong>{integration.label['it_IT']}</strong></p>
            <table
                key={integration.property}
                className="table table-striped table-striped-alt table-hover table-article-info">

                <tbody>
                {
                    <tr>
                        <td>
                            {integrationsDates[0].label['it_IT']} - {integrationsDates[1].label['it_IT']}
                        </td>
                        <td>
                            {getIntegrationValue(integrationsDates[0])} - {getIntegrationValue(integrationsDates[1])}
                        </td>
                    </tr>
                }

                {
                    <tr>
                        <td>
                            {integrationsCodeDescription[0].label['it_IT']} - {integrationsCodeDescription[1].label['it_IT']}
                        </td>
                        <td>
                            {getIntegrationValue(integrationsCodeDescription[0])} - {getIntegrationValue(integrationsCodeDescription[1])}
                        </td>
                    </tr>
                }

                {
                    integrationFiltered.map((integrationChild, index) => (

                        <tr key={integrationChild.property + index}>
                            <td>
                                {integrationChild?.label['it_IT'] ? integrationChild.label['it_IT'] : ''}
                            </td>

                            <td>
                                <strong>{getIntegrationValue(integrationChild)}</strong>
                            </td>
                        </tr>
                    ))
                }
                </tbody>
            </table>

                <div
                    className="overflow-x-scroll mt-3"
                >
                    <table className="table table-striped table-auto overflow-x-auto">
                        <thead>
                        <tr>
                            {
                                prezziCalcolatiHeadingSortedFiltered.map((item, index) =>
                                    //item.value > 0 ? (
                                        <th
                                            key={index}
                                            className="whitespace-nowrap"
                                            scope="row"
                                        >
                                            {item.label['it_IT']}
                                        </th>
                                    //) : null
                                )
                            }
                        </tr>
                        </thead>
                            <tbody>
                            {
                                prezziCalcolati.map((prezzoCalcolato, index) => (
                                    <PrezzoCalcolatoTableBody
                                        key={index}
                                        prezzoCalcolato={prezzoCalcolato}
                                    />

                                ))
                            }
                            {/*{JSON.stringify(prezziCalcolati, null, 2)}*/}
                            </tbody>
                    </table>
                </div>
            </div>
            )
            }

            export default SectionIntegration