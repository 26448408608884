import React, { useEffect, useState, useRef, useMemo } from 'react';
import { connect } from 'react-redux';
import { withI18n, Trans } from '@lingui/react';
import { useQuery } from 'react-query';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import format from 'date-fns/format';
import queryString from 'query-string';
import clsx from 'classnames';
import find from 'lodash/find';
import isObject from 'lodash/isObject';
import mapValues from 'lodash/mapValues';
import omit from 'lodash/omit';

import { getAvailableExchangeFormats, getFilteredExports, startExport } from '../api';
import { selectors } from '../reducers/filtersReducer';
import { selectors as appSelectors } from '../reducers/appReducer';
import { selectors as collectionSelectors } from '../reducers/collectionsReducer';
import ActiveFiltersTagsList from './ActiveFiltersTagsList';
import ExportHeaderModal from './ExportHeaderModal';
import { subDays } from 'date-fns';
import {
    getStoredFilters,
} from '../utils/LocalStorageFilterUtils'
import { getFromInternalPage } from '../reducers/pageStateReducer';
import { collectionBrandsCount } from '../helpers';
import DataTableWrapper from './ExportPanelComponents/DataTableWrapper';
import FormExportPanel from './ExportPanelComponents/FormExportPanel';

function ExportsPanel(props) {
    const qs = queryString.parse(props.location.search);

    const [exportType, setExportType] = useState(qs.type || 'Marca');
    const [showHeaderModal, setShowHeaderModal] = useState(false);
    const [availableExchangeFormats, setAvailableExchangeFormats] = useState([]);
    const [selectedExchangeFormat, setSelectedExchangeFormat] = useState(null);
    const [exportNotes, setExportNotes] = useState(null);
    let formRef = useRef(null);

    // brandFiler is the property that holds the exporting brand related to apiPaylod
    const brandFilter = props.brandFiler ? props.brandFiler?.toJS() : {}

    const localStorage = getStoredFilters()
    // Save in state the choosed brands
    const [brandsChoosed, setBrandsChoosed] = useState(0)

    const collectionId = qs.collectionId;

    const collections = props.collections.toJS().map((c) => {
        return {
            value: c.id,
            label: c.descrizione,
        };
    });

    const selectedCollection = useMemo(() => {
        return find(collections, { value: collectionId });
    }, [collectionId]);

    const initialFormValues = useMemo(() => {
        //console.warn('exportType ? => ', exportType)
        if (selectedCollection) {
            return {
                collection: selectedCollection,
            };
        }

        if (exportType === 'Marca') {
            const brandFilter = props.brandFiler?.toJS();

            if (brandFilter && brandFilter.value && brandFilter.value.length > 0) {
                setBrandsChoosed(brandFilter.value.length);
                return {
                    brands: brandFilter.value.map((b) => b.code),
                };
            }

            return {
                brands: [],
            };
        }

        if(exportType === 'Selezione') {
            setBrandsChoosed(brandFilter?.options?.length)
            //console.warn('stiamo esportando una selezione', brandFilter?.options?.length, brandFilter?.options)
        }

        return {};
    }, [selectedCollection, exportType])

    const dateFrom = subDays(new Date(), props.exportsListDaysLimit);

    const queryFilters = {
        started_from: format(dateFrom, 'YYYY-MM-DD'),
        started_to: format(new Date(), 'YYYY-MM-DD'),
    };

    const {
        data: exportsData,
        refetch,
    } = useQuery(['filteredExports', queryFilters], getFilteredExports, {
        refetchOnWindowFocus: false,
    });

    useEffect(async () => {
        const exchangeFormatsRes = await getAvailableExchangeFormats();

        setAvailableExchangeFormats(exchangeFormatsRes.data);
    }, []);

    const onSubmit = async (data) => {
        const currentExchangeFormat = find(availableExchangeFormats, {
            code: data.exchange_format.value,
        });

        if (currentExchangeFormat.header.length > 0) {
            setShowHeaderModal(true);
            setSelectedExchangeFormat(currentExchangeFormat);
            setExportNotes(data.notes || null);
        } else {
            await onSubmitProper(data);
        }
    };

    const onSubmitProper = async (data, onlyHeader = false) => {
        let payload = data;

        let filter = {};

        if (exportType === 'Marca') {
            const baseFormState = formRef.current.getState();
            console.warn('baseFormState', baseFormState)
            filter = {
                sigle_marca: baseFormState.values.brands,
            };
        } else if (exportType === 'Selezione') {
            filter = omit(props.apiPayload, [
                'product_tree',
                'locale',
                'page',
                'per_page',
                'include_ricambi',
                'include_accessori',
                'sort',
                'sort_direction',
            ]);
        } else if (exportType === 'Collezione') {
            const baseFormState = formRef.current.getState();

            filter = {
                collezione: baseFormState.values.collection.value,
            };
        }

        if (filter && filter.sigle_marca && filter.sigle_marca.length > 1) {
            if (selectedExchangeFormat.multimarca === false) {
                toast(
                    `Il formato selezionato (${selectedExchangeFormat.name}) non supporta l'export di più marche. Selezionarne uno con la dicitura (multimarca) e riprovare!`,
                    {
                        position: 'bottom-right',
                        type: toast.TYPE.ERROR,
                    }
                );
            }

            return;
        }

        if (onlyHeader) {
            payload = {
                code: selectedExchangeFormat.code,
                filter,
                notes: exportNotes,
                header: mapValues(data, (v) => {
                    return isObject(v) ? v.value : v;
                }),
            };
        }

        await startExport(payload);

        toast('Richiesta di export effettuata correttamente', {
            position: 'bottom-right',
            type: toast.TYPE.SUCCESS,
            autoClose: 10000,
        });

        formRef.current.restart();

        refetch();
    };

    const handleBrandsChange = (value) => {
        const newBrandsCount = value.length;
        //console.warn('Brand Count from ExportsPanel handleBrandsChange', newBrandsCount)
        setBrandsChoosed(newBrandsCount);
    }

    const handleCollectionChange = async (value) => {
        const collectionBrands = await collectionBrandsCount(value.value)
        setBrandsChoosed(collectionBrands)
    }

    return (
        <div className="grid grid-cols-12 mx-2">
            {showHeaderModal && (
                <ExportHeaderModal
                    onClose={() => setShowHeaderModal(false)}
                    exchangeFormat={selectedExchangeFormat}
                    onSubmit={onSubmitProper}
                />
            )}
            <div className="col-span-12 text-center mt-2">
                <h2 className="text-primary">Download</h2>
            </div>
            <div className="col-span-12 xl:col-span-6 xl:col-start-4">
                <div
                    className={clsx('grid gap-2', {
                        'grid-cols-3': props.enableExportLists,
                        'grid-cols-2': !props.enableExportLists,
                    })}
                >
                    <div
                        className={clsx('export-type-btn', {
                            'export-type-current': exportType === 'Marca',
                        })}
                        onClick={() => setExportType('Marca')}
                    >
                        <Trans id="export brands" />
                    </div>
                    {props.enableExportLists && (
                        <div
                            className={clsx('export-type-btn', {
                                'export-type-current': exportType === 'Collezione',
                            })}
                            onClick={() => {
                                setBrandsChoosed(0)
                                setExportType('Collezione')
                            }
                            }
                        >
                            <Trans id="export lists" />
                        </div>
                    )}
                    <div
                        className={clsx('export-type-btn', {
                            'export-type-current': exportType === 'Selezione',
                            'export-type-disabled': qs.type === 'Marca',
                        })}
                        // onClick={() => (props.isAnyFilterActive ? setExportType('Selezione') : {})}
                        onClick={() => (qs.type !== 'Marca' ? setExportType('Selezione') : {})}
                    >
                        <Trans id="export selection" />
                    </div>
                </div>
                {exportType === 'Selezione' && (
                    <div className="bg-gray p-2 min-h-8 mt-2 flex">
                        <b className="text-primary mr-4 capitalize">
                            <Trans id="active filters" />
                        </b>
                        <ActiveFiltersTagsList showClearBtn={false} />
                    </div>
                )}
                {exportType && (
                    <div>
                        <FormExportPanel
                            formRef={formRef}
                            initialFormValues={initialFormValues}
                            onSubmit={onSubmit}
                            exportType={exportType}
                            availableExchangeFormats={availableExchangeFormats}
                            brandsChoosed={brandsChoosed}
                            handleBrandsChange={handleBrandsChange}
                            collections={collections}
                            handleCollectionChange={handleCollectionChange}
                            isAnyFilterActive={props.isAnyFilterActive}
                        />
                    </div>
                )}
            </div>
            <DataTableWrapper
                exportsListDaysLimit={props.exportsListDaysLimit}
                exportType={exportType}
            />
        </div>
    );
}

function mapStateToProps(state) {
    return {
        apiPayload: selectors.getApiPayload(state),
        isAnyFilterActive: selectors.isAnyFilterActive(state),
        exportsListDaysLimit: appSelectors.getExportsListDaysLimit(state),
        collections: collectionSelectors.getDisplayItems(state),
        brandFiler: selectors.getFilter(state, 'brand'),
        enableExportLists: appSelectors.getEnableExportLists(state),
        fromInternalPage: getFromInternalPage(state)
    };
}

const ConnectedExportsPanel = connect(mapStateToProps)(ExportsPanel);

const RouterConnectedExportsPanel = withRouter(ConnectedExportsPanel);

export default withI18n()(RouterConnectedExportsPanel);