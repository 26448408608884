import sortBy from 'lodash/sortBy'
import { getLocaleFromLanguageCode } from '../../intl-helpers'
import selectors from '../../reducers/appReducer'

/**
 * Sorts the tree recursively based on leaf names.
 *
 * @param {Array} leafs - The list of leaf nodes to sort.
 * @param {string} locale - The locale used for sorting.
 * @returns {Array} - The sorted list of leaf nodes.
 */
export function sortTree(leafs, locale) {
    return sortBy(leafs, (leaf) => {
        if (leaf.children && leaf.children.length > 0) {
            leaf.children = sortTree(leaf.children, locale);
        }

        return leaf.name;

        // FIXME: tradurre
        // return leaf.name ? leaf.name[locale] : 'Non classificato';
    });
}

/**
 * Searches for a node within the tree that matches a given property value.
 *
 * @param {Object|Array} tree - The tree or list of nodes to search.
 * @param {string} nodesProp - The property name that holds child nodes.
 * @param {string} prop - The property name to match.
 * @param {*} value - The value to search for.
 * @returns {Object|null} - The found node or null if not found.
 */
export function searchTree(tree, nodesProp, prop, value) {
    let i,
        f = null;
    if (Array.isArray(tree)) {
        for (i = 0; i < tree.length; i++) {
            f = searchTree(tree[i], nodesProp, prop, value);
            if (f) {
                return f;
            }
        }
    } else if (typeof tree === 'object') {
        if (tree[prop] !== undefined && tree[prop] === value) {
            return tree;
        }
    }
    if (tree[nodesProp] !== undefined && tree[nodesProp].length > 0) {
        return searchTree(tree[nodesProp], nodesProp, prop, value);
    } else {
        return null;
    }
}
/**
 * Gets the label of an item based on the locale.
 *
 * @param {Object} name - The name object containing localized names.
 * @param {string} code - The code to use if the name is not available.
 * @returns {string} - The localized name or a fallback value.
 */
export function getItemLabel(name, code) {
    if (name && name[getLocale()]) {
        return name[getLocale()];
    }

    if (code) {
        return `[${code}]`;
    }

    return '[n.d.]';
}
/**
 * Gets the current locale.
 *
 * @returns {string} - The current locale.
 */
export function getLocale() {
    return getLocaleFromLanguageCode(selectors.getLanguage);
}
/**
 * Localizes the name of each leaf in the tree recursively.
 *
 * @param {Object} leaf - The leaf node to localize.
 * @param {Function} getItemLabel - The function used to get the localized label.
 * @returns {Object} - The localized leaf node.
 */
export const localizeLeafsName = (leaf, getItemLabel) => {
    return {
        ...leaf,
        name: getItemLabel(leaf.name, leaf.code),
        children: leaf.children.map((child) => localizeLeafsName(child, getItemLabel)),
    };
};

/**
 * Builds a new tree leaf based on the filter value.
 *
 * @param {Object} node - The node to process.
 * @param {string} filterValue - The value to filter nodes by.
 * @returns {Object} - The new tree leaf.
 */
export const buildNewTreeLeaf = (node, filterValue) => {
    let leaf = {
        id: node.id,
        code: node.code,
        filterParams: node.filterParams,
        name: node.name,
        state: { filtered: node.state.filtered },
        children: [],
    };

    const words = filterValue.match(/\b(\w+)\b/g);

    if (words === null || words.length === 1) {
        if (node.name.toLowerCase().match(new RegExp(`${filterValue}`, 'g'))) {
            leaf.state.open = true;
        }
    } else {
        words.forEach((w) => {
            if (node.name.toLowerCase().match(new RegExp(`${w.toLowerCase()}`, 'g'))) {
                leaf.state.open = true;
            }
        });
    }

    if (node.children && node.children.length > 0) {
        node.children.forEach((childNode) => {
            const leafChild = buildNewTreeLeaf(childNode, filterValue);

            if (leafChild.state.open) {
                leaf.state.open = true;
            }

            leaf.children.push(leafChild);
        });
    }

    return leaf;
};
/**
 * Searches the nodes to open based on the filter value.
 *
 * @param {Array} nodes - The list of nodes to search.
 * @param {string} filterValue - The value to filter nodes by.
 * @returns {Array} - The new list of nodes with the filtered state.
 */
export const searchNodesToOpen = (nodes, filterValue) => {
    return nodes.map((node) => buildNewTreeLeaf(node, filterValue));
};