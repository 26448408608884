import React from 'react'
import { Trans } from '@lingui/react';
import { FaRedo } from 'react-icons/fa';
import DataTableExportPanel from './DataTableExportPanel';
import { useQuery } from 'react-query';
import { subDays } from 'date-fns';
import format from 'date-fns/format';
import { getFilteredExports } from '../../api';

const DataTableWrapper = (props) => {

    const dateFrom = subDays(new Date(), props.exportsListDaysLimit)

    const queryFilters = {
        started_from: format(dateFrom, 'YYYY-MM-DD'),
        started_to: format(new Date(), 'YYYY-MM-DD'),
    }

    const {refetch} = useQuery(['filteredExports', queryFilters], getFilteredExports, {
        refetchOnWindowFocus: false,
    })
    return (
        <div className="col-span-12 mt-12">
            <div className="text-primary text-xl">
                <Trans id="request status" />
            </div>
            <div className="flex items-center mb-1">
                <button
                    className="btn btn-link mr-0.5"
                    onClick={refetch}
                    disabled={props.exportType === null}
                >
                    <FaRedo />
                </button>
                <span className="text-primary">
                        <Trans id="click the icon to refresh" />
                    </span>
            </div>
            {props.exportType !== null && (
                <DataTableExportPanel
                    exportsListDaysLimit={props.exportsListDaysLimit}
                    exportType={props.exportType}
                ></DataTableExportPanel>
            )}
        </div>
    )
}

export default DataTableWrapper;