import { call, put, /*fork,*/ select, takeEvery } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { hash, fromJS } from 'immutable';

import { actionTypes, actions } from '../actions/productClassesActions';
import { fetchItems, fetchItemsFromLocalStorage } from '../actions/itemsActions';
import { actions as sectorActions } from '../actions/sectorsActions';
import { selectors } from '../reducers/productClassesReducer';
import { selectors as filtersSelectors } from '../reducers/filtersReducer';
import { selectors as sectorsSelectors } from '../reducers/sectorsReducer';
// import { refreshFiltersValues } from './filtersSaga';
import { fetchEtimFeatures, fetchEtimFeatureValues } from '../api';
import { ETIM_CLASSIFICATION } from '../constants';
import {
    createLocalStorage, deleteEtimFeatureInLocalStorage,
    getFromLocalStorage,
    removeFilterFromLocalStorage
} from '../utils/LocalStorageFilterUtils';
import { getIsChangingClassification, getIsLoadingFromLocalStorage } from '../reducers/pageStateReducer';
import * as api from '../api';
import { actions as filtersActions, setFuoriCartaceoEnabled } from '../actions/filtersActions';
import * as constants from '../constants';
import { haveFuoriListinoCartaceoProperty } from '../helpers';

function* changeProductClassFilterSaga(action) {
    yield put(actions.resetProductClass());

    const classificationType = yield select(sectorsSelectors.getFilterValue);

    const loadingFromLocalStorage = yield select(getIsLoadingFromLocalStorage)
    const isChangingClassification = yield select(getIsChangingClassification)

    try {
        // if (action.payload === null) {
        //     yield fork(refreshFiltersValues);
        // } else {

        if (action.payload !== null) {
            // Check if new classification have fuori_listino_cartaceo products and can be enabled
            const apiPayload = yield select(filtersSelectors.getApiPayload)
            const haveFuoriCartaceo = yield call(haveFuoriListinoCartaceoProperty, apiPayload)
            yield put(setFuoriCartaceoEnabled(haveFuoriCartaceo))

            // Change classification_path or etim_class
            /*!loadingFromLocalStorage && removeFilterFromLocalStorage('etim_features')*/

            yield put(actions.fetchProductClassAttributes());

            if (classificationType === ETIM_CLASSIFICATION) {
                // remove previously stored etim_features
                removeFilterFromLocalStorage('etim_features')
                yield put(sectorActions.setSelectedItemTree(action.payload.value));
            }
        } else {
            // Delete classification_path or etim_class
            // TODO: mi resetta l'oggetto selezionato nell'albero anche se non dovrebbe (seleziono un gruppo diverso da quello della classe attuale)
            if (classificationType === ETIM_CLASSIFICATION) {
                // remove the etim_features stored because are etim_class related
                removeFilterFromLocalStorage('etim_features')
                const depth = yield select(sectorsSelectors.getSelectedTreeItemDepth);

                if (depth === 2) {
                    yield put(sectorActions.resetSelectedTreeItem());
                }
            }
        }

        // Fetcho Items solo DOPO aver modificato la classe nei filtri in maniera da non fetcharli con il valore precedente
        yield delay(1000) // A huge delay because we need to be sure isn't running from fetchSectorsSaga the setFilterValue for brand to null
        //console.warn('stiamo lanciando una fetch items da changeProductClassFilterSaga')
        if(!isChangingClassification){
            yield put(fetchItems())
        }

    } catch (err) {
        // TODO: gestire errori
        console.error(err);
    }
}

function* fetchProductClassAttributesSaga(action) {
    try {
        yield put(actions.fetchProductClassAttributesStart());

        const apiPayload = yield select(filtersSelectors.getApiPayload);

        // yield fork(refreshFiltersValues, apiPayload);
        /*const localRes = getFromLocalStorage(apiPayload.etim_class)
        if(localRes) {
            const localResData = {data: localRes[apiPayload.etim_class]}
            console.log('### LOCAL RES', localRes[apiPayload.etim_class])
            yield put(actions.fetchProductClassAttributesSuccess(localResData))
            return
        }*/
        const res = yield call(fetchEtimFeatures, apiPayload);
        // console.log('### res =>', res)
        // ###STORE OPTIONS IN LOCAL STORAGE
        // console.log("### API PAYLOAD => ", apiPayload.etim_class)
        // createLocalStorage([apiPayload.etim_class], {[apiPayload.etim_class]: res.data})
        // createLocalStorage('etim_features', {[apiPayload.etim_class]: res.data})

        yield put(actions.fetchProductClassAttributesSuccess(res));
    } catch (err) {
        // TODO: gestire errori
        console.error(err);
    }
}

// This functions remove only the etim_features attributes, that are stored in state as activeFilters
function* resetAllProductClassAttributesFiltersSaga() {
    removeFilterFromLocalStorage('etim_features')
    // Added delay to be sure the reducers have reset state.activeFilters
    const brands = yield call(api.fetchBrandFilter)
    //console.warn('qui dovrebbe passarmi tutti i brands! => ', brands)
    yield put(filtersActions.setFilterOptions(constants.BRAND_FILTER, brands.data))
    yield delay(1000)
    yield put(fetchItemsFromLocalStorage());
}

function* fetchProductClassAttributeDataSaga(action) {
    //console.log('pppp =>', action)
    const id = action.payload.id;

    const attribute = yield select(selectors.getProductClassAttribute, id);
    const apiPayload = yield select(filtersSelectors.getApiPayload);

    // Mi calcolo l'hash del payload
    const requestHash = hash(fromJS(apiPayload));
    const samehash = requestHash === attribute.lastRequestHash;

    // Non fetcho se sto gia fetchando o se è lo stesso hash dell'ultima richiesta eseguita correttamente
    if (attribute.isFetching === true || samehash) {
        return false;
    }

    yield put(actions.fetchProductClassAttributeDataStart(id));

    try {
        const res = yield call(fetchEtimFeatureValues, id, apiPayload);

        yield put(actions.fetchProductClassAttributeDataSuccess(id, res.data, requestHash));
    } catch (e) {
        yield put(actions.fetchProductClassAttributeDataFail(id, e));
    }
}

function* filterByProductClassAttributeSaga(action = null) {
    if(!action?.payload?.attribute && action.payload) deleteEtimFeatureInLocalStorage(action.payload)
    action?.payload?.attribute ? deleteEtimFeatureInLocalStorage(action?.payload?.attribute) : ''

    yield delay(100);
    yield put(fetchItems());
}

export default [
    takeEvery(actionTypes.FETCH_PRODUCT_CLASS_ATTRIBUTES, fetchProductClassAttributesSaga),
    takeEvery(actionTypes.SET_PRODUCT_CLASS_VALUE, changeProductClassFilterSaga),
    takeEvery(
        actionTypes.RESET_PRODUCT_CLASS_ATTRIBUTES_FILTERS_ALL,
        resetAllProductClassAttributesFiltersSaga
    ),
    takeEvery(actionTypes.FETCH_PRODUCT_CLASS_ATTRIBUTE_DATA, fetchProductClassAttributeDataSaga),
    takeEvery(
        [
            actionTypes.SET_PRODUCT_CLASS_ATTRIBUTE_LOGIC,
            actionTypes.SET_PRODUCT_CLASS_ATTRIBUTE_NUMERIC,
            actionTypes.TOGGLE_PRODUCT_CLASS_ATTRIBUTE_FILTER,
            actionTypes.RESET_PRODUCT_CLASS_ATTRIBUTE_FILTERS,
        ],
        filterByProductClassAttributeSaga
    ),
];
