import React, { useRef } from 'react';
import { Field, Form } from 'react-final-form';
import { SelectBrandsMultipleField } from '../form/SelectBrandsMultiple';
import SelectField from '../form/SelectField';
import { i18nMark, Trans } from '@lingui/react';
import TextareaField from '../form/TextareaField';
import SubmitButton from '../form/SubmitButton';

function validate(values, type) {
    const mandatoryFieldError = i18nMark('validation:error:mandatory:field');
    let errors = {};

    // console.log(values);

    if (!values.exchange_format) {
        errors.exchange_format = mandatoryFieldError;
    }

    if (type === 'Marca') {
        if (!values.brands || values.brands.length === 0) {
            errors.brands = mandatoryFieldError;
        }
    }

    if (type === 'Collezione') {
        if (!values.collection) {
            errors.collection = mandatoryFieldError;
        }
    }

    return errors;
}

const FormExportPanel = (props) => {

    // Use ref for keep track of the brands selected
    const brandsSelectedRef = useRef([])
    // Local function to keep track of the selected brands
    const onBrandChange = (selectedItems) => {
        // Check if the value received is empty
        if (!selectedItems || selectedItems.length === 0) {
            // If empty, empty the ref
            brandsSelectedRef.current = [];
        } else {
            // Otherwise, update ref with new items list
            brandsSelectedRef.current = [...selectedItems];
        }
        props.handleBrandsChange(brandsSelectedRef.current)
    }

    return (
        <Form
            onSubmit={props.onSubmit}
            validate={(values) => validate(values, props.exportType)}
            initialValues={props.initialFormValues}
            render={({
                         handleSubmit,
                         pristine,
                         hasValidationErrors,
                         submitError,
                         submitting,
                         errors,
                         touched,
                         form,
                         ...rest
                     }) => {
                if (form) {
                    props.formRef.current = form;
                }

                return (
                    <form
                        onSubmit={handleSubmit}
                        className="form-vertical text-primary"
                    >
                        <div className="grid grid-cols-2 gap-2 mb-2 mt-4">
                            {props.exportType === 'Marca' && (
                                <div className="col-span-2 xl:col-span-1">
                                    <Field
                                        name="brands"
                                        component={SelectBrandsMultipleField}
                                        valueKey="code"
                                        onCustomChange={(value) => onBrandChange(value)}
                                    />
                                </div>
                            )}
                            {props.exportType === 'Collezione' && (
                                <div className="col-span-2 xl:col-span-1">
                                    <Field
                                        name="collection"
                                        component={SelectField}
                                        options={props.collections}
                                        layout="vertical"
                                        label="Seleziona una lista"
                                        placeholder="Seleziona una lista"
                                        className="form-input input-lg"
                                        onCustomChange={(item) => props.handleCollectionChange(item)}
                                    />
                                </div>
                            )}
                            <div
                                className={
                                    props.exportType !== 'Selezione'
                                        ? 'col-span-2 xl:col-span-1'
                                        : 'col-span-2'
                                }
                            >
                                <Field
                                    name="exchange_format"
                                    component={SelectField}
                                    options={props.availableExchangeFormats.map((e) => {
                                        let label = e.name

                                        if (e.multimarca) {
                                            label += ' (multimarca)';
                                        }
                                        // Disable the option if brandsChoosedRef > 1 and e.multimarca is false
                                        let isDisabled = props.brandsChoosed > 1 && e.multimarca === false

                                        return {
                                            value: e.code,
                                            label,
                                            disabled: isDisabled,
                                        }
                                    })}
                                    layout="vertical"
                                    placeholder={i18nMark('select exchange format')}
                                    className="form-input input-lg"
                                    disabled={
                                        props.exportType === 'Selezione' &&
                                        !props.isAnyFilterActive
                                    }
                                />
                            </div>
                        </div>
                        <div className="mb-4">
                            <Field
                                name="notes"
                                component={TextareaField}
                                layout="vertical"
                                placeholder={i18nMark('note')}
                                className="form-input input-lg"
                                disabled={
                                    props.exportType === 'Selezione' &&
                                    !props.isAnyFilterActive
                                }
                            />
                        </div>
                        <SubmitButton
                            submitting={submitting}
                            disabled={
                                pristine ||
                                (props.exportType === 'Selezione' &&
                                    !props.isAnyFilterActive)
                            }
                        >
                            <Trans id="request" />
                        </SubmitButton>
                    </form>
                );
            }}
        />

    )
}

export default FormExportPanel