import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

const TreeNode = ({ node, tree, selectedItem, onItemClick }) => {
    const { id, name, state } = node
    const { depth, open } = state
    const more = node.hasChildren()
    const isSelected = selectedItem === id

    return (
        <div
            className={cx('infinite-tree-item', {
                'bg-secondary': isSelected,
            })}
            data-id={id}
        >
            <div className="infinite-tree-node" style={{ marginLeft: depth * 18 }}>
                {more && open && (
                    <span
                        className={cx(tree.options.togglerClass, 'c-hand')}
                        onClick={() => tree.closeNode(node)}
                    >
                        <i className="icon icon-small icon-arrow-down mr-1 text-primary" />
                    </span>
                )}
                {more && !open && (
                    <span
                        className={cx(tree.options.togglerClass, 'c-hand', 'infinite-tree-closed')}
                        onClick={() => tree.openNode(node)}
                    >
                        <i className="icon icon-small icon-plus mr-1 text-primary" />
                    </span>
                )}
                {!more && (
                    <span className={cx(tree.options.togglerClass, 'infinite-tree-closed')}>
                        <i className="icon icon-small icon-minus mr-1 text-primary" />
                    </span>
                )}
                <span
                    className={cx(
                        'infinite-tree-title',
                        'c-hand',
                        'tooltip',
                        'tooltip-wide',
                        {
                            'tooltip-bottom': state.depth < 2,
                        }
                    )}
                    data-tooltip={name}
                    onClick={() => onItemClick(node)}
                >
                    <span
                        className={
                            isSelected ? 'infinite-tree-leaf text-bold' : 'infinite-tree-leaf'
                        }
                    >
                        {name}
                    </span>
                </span>
            </div>
        </div>
    )
}

TreeNode.propTypes = {
    node: PropTypes.object.isRequired,
    tree: PropTypes.object.isRequired,
    selectedItem: PropTypes.string,
    onItemClick: PropTypes.func.isRequired,
}

export default TreeNode
